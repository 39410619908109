import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt, 
  faComments,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';

export default function PlanSteps()  {
    
  return (
    <React.Fragment>
      <Row>
        <Col>
          <p style={{ 
            marginTop: '2rem', 
            marginBottom:'1rem',
            fontStyle: 'oblique',
            fontSize: '1.3rem',
            textAlign: 'center',
            }}>It’s easy to get great service:</p>
        </Col>
      </Row>
      <Row style={{ textAlign: 'center' }}>
        <Col sm>
          <FontAwesomeIcon icon={faFileAlt} className='cg-stepIcon' />
          <p>1. Fill out the form below to get a quote</p>
        </Col>
        <Col sm>
          <FontAwesomeIcon icon={faComments} className='cg-stepIcon' />
          <p>2. We'll reply promptly to discuss project details</p>
        </Col>
        <Col sm>
          <FontAwesomeIcon icon={faTruck} className='cg-stepIcon' />
          <p>3. We'll agree on a date and begin work</p>
        </Col>
      </Row>
    </React.Fragment>
    )
}