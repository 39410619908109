import React, {useState} from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import PlanSteps from "../components/planSteps";
import Review from "../components/review";
import StyledBackgroundSection from "../components/backgroundDecks";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col, 
  Card,
  CardGroup
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowAltCircleUp, 
  faArrowAltCircleDown, 
} from '@fortawesome/free-regular-svg-icons';
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Siding = () => {
    const data = useStaticQuery(graphql`
    query {
    
      work1: file(relativePath: { eq: "decks/20240626_111127.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work2: file(relativePath: { eq: "decks/20240624_111028.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work3: file(relativePath: { eq: "decks/20240624_110905.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work4: file(relativePath: { eq: "decks/20240620_094706.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  
                
  const [contactTxt] = useState('');
  

  return (
  <Layout>
    <SEO title="Deck Contractor" path='deck-contractor'/>
    <StyledBackgroundSection/>
    <AboveFold
      title='Decking'
      subtitle="Build a Deck that Lasts"
      >
    </AboveFold>
    
    <Hr/>
    
    <main id='main'>
      
      
      <div id='guide' style={{ textAlign:'center', paddingBottom:'4rem' }}  className='cg-sectionLight'>
        <div className='cg-section'>
          <SectionHeading>Our Work</SectionHeading>
          <Container fluid>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        These photos are from recent projects. To see more, visit the portfolio.
                      </Card.Text>
                      <Link className='btn btn-secondary' to='/portfolio-decks'>Portfolio</Link>
                    </Card.Body>
                  </Card>
              </Col>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work1.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work2.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work3.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work4.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
              <Col style={{ marginTop:'2rem', paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        
      <Hr/>
      
      <div id='guide' style={{ textAlign:'center' }}  className='cg-sectionShaded'>
        <div className='cg-section'>
          <SectionHeading>Deck Reviews</SectionHeading>
          <Container fluid>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Pool Deck' name='Danielle Insalaco'>
                    Another job well done by MLB.  We had a <strong>deck</strong> installed around our above ground pool.  This was our second project with MLB and they did not fail to impress us once again
                  </Review>
                  <Review title='Excellent Job' name='Frank Goico'>
                    Mike and his guys did a <strong>deck</strong> for us and did an excellent job. We continue to go to Mike for he keeps good communication and has excellent craftmanship. Very pleased once again. Highly recommended!
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Roof & Deck' name='Larry Langett'>
                    Mike and his crew did a great job on my knotty pine room addition and complete roof and  <strong>new deck</strong> . Beautiful work. Mike is a construction super star. 
                  </Review>
                  <Review title='Professional' name='Roxanna Pisiak'>
                    Did a great job on our roof and <strong>deck</strong>.  [...] The workers are polite and professional, and don't leave a big mess behind...
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <p>To get service like this <FontAwesomeIcon icon={faArrowAltCircleUp} /> contact&nbsp;us!&nbsp;<FontAwesomeIcon icon={faArrowAltCircleDown} /> </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Link className='btn btn-secondary' to='/reviews#main'>See More Reviews</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
      
    </main>
    
    <ContactForm message={contactTxt}/> 
    
    
  </Layout>
)};

export default Siding;
